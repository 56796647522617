// 通用方法
import CryptoJS from 'crypto-js'; //md5 加密

//! 返回顶部
export function top(duration = 500) {
  const scrollStep = -window.scrollY / (duration === 0 ? 1 : duration / 15);
  let scrollInterval: any;

  function step() {
    if (window.scrollY === 0) {
      clearInterval(scrollInterval);
    } else {
      window.scrollBy(0, scrollStep);
    }
  }

  scrollInterval = setInterval(step, 15);
}


// 用来截取地址参数的
export function searchUrl(name: string) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  let r = window.location.search.substr(1).match(reg);
  return r
}


// 当前是什么端，什么类型
export function pageType() {
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isAndroid) return 'android'
  if (isiOS) return 'ios'
  return 'pc'
}



/**
 * 用来截取？后面的参数 
 * @param {string} url  要截取的第地址: https://xxx.com/?id=1
 * @param {string} variable  参数名: 例如:id
 * @returns 
 */
export function getQueryVariable(url: string, variable: string) {
  var query = url?.split("?")[1]; // 根据“?”切割数组，截取“?”之后的字符串
  if (!query) return
  // ['https://qq.com','appid=1234567890&name=Tom']
  var vars = query?.split("&"); // 根据“&”切割成数组
  // ['appid=1234567890','name=Tom']
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i]?.split("=");
    // ['appid','1234567890']
    if (pair[0] == variable) return pair[1]; // 没有花括号默认执行第一个js语句
  }
  return;
}


// 判断横竖屏 
export function rotate() {
  let screen = false //是否是横屏  false == 不是   true == 是
  let time = setTimeout(() => {
    if (window.orientation == 180 || window.orientation == 0) {
      // 竖屏
      screen = false
    } else if (window.orientation == 90 || window.orientation == -90) {
      // 横屏
      screen = true
    }
    clearTimeout(time)
    // return screen
  }, 300)
  return screen
}

// 生成一个message   content:提示文字  duration:时间
export function message(content: string, duration?: number) {
  const div = document.createElement('div');
  div.className = 'page-message';
  // 创建一个 p 元素
  const p = document.createElement('p');
  p.className = 'cont';
  p.textContent = content;
  // 将 p 元素添加到 div 中
  div.appendChild(p);
  // 将 div 元素添加到 body 中
  document.body.appendChild(div);
  let timer = setTimeout(() => {
    clearTimeout(timer)
    document.body.removeChild(div);
  }, duration ?? 2000);

  // 返回一个清理函数，在组件卸载时移除该元素
  return () => {
    document.body.removeChild(div);
  };
}

// 生成一个确认/返回弹框 标题、消息内容、按钮类型以及按钮的回调函数
export function confirmDialog(icon: 'success' | 'error' | 'info', title: string, messages: string[], buttonType: 'single' | 'double', onConfirm?: () => void, onCancel?: () => void) {
  // 创建遮罩层
  const overlay = document.createElement('div');
  overlay.className = 'confirm-dialog-overlay';

  // 创建弹框主体
  const dialog = document.createElement('div');
  dialog.className = 'confirm-dialog';

  // 创建图标
  const iconElement = document.createElement('div');
  iconElement.className = `confirm-${icon}`;
  dialog.appendChild(iconElement);

  // 创建标题
  const titleElement = document.createElement('h3');
  titleElement.className = `confirm-title ${icon}`;
  titleElement.textContent = title;
  dialog.appendChild(titleElement);

  // 创建消息内容
  messages.forEach(message => {
    const p = document.createElement('p');
    p.className = `confirm-text`;
    p.textContent = message;
    dialog.appendChild(p);
  });

  // 创建按钮区域
  const buttonsContainer = document.createElement('div');
  buttonsContainer.className = 'confirm-dialog-buttons';

  // 根据按钮类型添加按钮
  if (buttonType === 'double') {
    const cancelButton = document.createElement('button');
    cancelButton.textContent = '取消';
    cancelButton.style.width = '50%'; // 设置按钮宽度为50%
    cancelButton.className = `cancel-btn`;
    cancelButton.onclick = () => {
      document.body.removeChild(overlay);
      onCancel && onCancel();
    };
    buttonsContainer.appendChild(cancelButton);

    const confirmButton = document.createElement('button');
    confirmButton.textContent = '确认';
    confirmButton.style.width = '50%'; // 设置按钮宽度为50%
    confirmButton.className = `confirm-btn`;
    confirmButton.onclick = () => {
      document.body.removeChild(overlay);
      onConfirm && onConfirm();
    };
    buttonsContainer.appendChild(confirmButton);
  } else if (buttonType === 'single') {
    const returnButton = document.createElement('button');
    returnButton.textContent = '返回';
    returnButton.style.width = '100%'; // 设置按钮宽度为100%
    returnButton.className = `return-btn`;
    returnButton.onclick = () => {
      document.body.removeChild(overlay);
      onCancel && onCancel();
    };
    buttonsContainer.appendChild(returnButton);
  }

  dialog.appendChild(buttonsContainer);
  overlay.appendChild(dialog);
  document.body.appendChild(overlay);

  // 返回一个清理函数，以便可以在需要时手动清除弹框
  return () => {
    if (document.body.contains(overlay)) {
      document.body.removeChild(overlay);
    }
  };
}

// md5加密
export const sign = (data: any) => {
  const sortedKeys = Object.keys(data).sort();  // 将对象的键按照升序排序
  const formattedString = sortedKeys.map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');   //拼接
  let sign = formattedString + localStorage.getItem('token')
  const md5Hash = CryptoJS.MD5(sign).toString();
  return md5Hash
}

// 千位数值变换(带逗号)
export const formatNumber = (num: number): string => {
  return new Intl.NumberFormat().format(num);
};

// 手机号加*号
export const maskPhoneNumber = (phoneNumber: string): string =>{
  // 正则表达式用于匹配手机号中间四位数字并替换为星号
  return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}