import React, { useEffect } from 'react'

if (/(iPhone|iPad|iPod|iOS|Android|Windows Phone|BlackBerry|SymbianOS)/i.test(navigator.userAgent)) {
  import('./lyFooter-m.less');
} else {
  import('./lyFooter.less');
}


export default function LyFooter() {
  useEffect(() => {
    // alert(document.body.clientHeight )
  })

  return (
    <div className='ly-footer'>
      <div className='footer-left'></div>
      <div className='footer-right'>
        <div className='footer-text1'>
          {/* 用户协议 | 隐私政策 | 儿童隐私政策 | 自律公约 | */}
          <a href='https://www.giraffar.com/sa/user.html' target='_blank' > 用户协议 </a> |
          <a href='https://www.giraffar.com/sa/privacy.html' target='_blank' > 隐私政策 </a> |
          <a href='http://giraffar.com/#/childerGuard' target='_blank' > 成长关爱 </a>
          {/* <a href='https://sa.giraffar.com/view/productFun/index.html' target='_blank' > 产品功能 </a> */}
          {/* <a href='http://172.16.0.178:8080/#/childerGuard' target='_blank' > 成长关爱 </a> */}
          {/* | 关于我们 | 联系我们 | 加入我们 */}
        </div>
        <div className='footer-text2'>
          适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活<br />
          抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当<br />
        </div>
        <div className='footer-text3'>
          <span>COPYRIGHT © 2023 上海鹿深科技有限公司</span>

          <a href="https://beian.miit.gov.cn/" target="_blank" className='footer-records'>&nbsp;&nbsp; 沪ICP备2022009975号-1</a> 
          {/* &nbsp;&nbsp; | &nbsp;&nbsp; 国新出审[2022]1578号 &nbsp;&nbsp;&nbsp; */}
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014701" className='footer-records footer-records-flex'>
            <img src="https://www.giraffar.com/web-static/sa/sa-web/footer-icon.png" className='footer-icon' />
            <p >&nbsp;&nbsp;沪公网安备 31011202014701号</p>
          </a><br />
        </div>

      </div>
    </div>
  )
}
