import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Game } from "@/enum/game"
import { FlowType } from "@/enum/flow"
import { message, confirmDialog, sign } from '@/utils/common';

import SaFooter from '../../components/saFooter/saFooter';
import MlFooter from '../../components/mlFooter/mlFooter';
import Login from '@/components/login/login';
import { LoginData, Role, RoleOptions } from '@/utils/type'

if (/(iPhone|iPad|iPod|iOS|Android|Windows Phone|BlackBerry|SymbianOS)/i.test(navigator.userAgent)) {
    import('./flow-m.less');
} else {
    import('./flow.less');
}
import { Helmet } from 'react-helmet';

export default function Flow() {
    const navigate = useNavigate()
    const [page, setPage] = useState(() => (React as any).pageType);   //用来区分不同的端
    const [server, setServer] = useState<Role | any>()
    const [serverOptions, setServerOptions] = useState<any>(() => {
        let option = localStorage.getItem('option') != null ? localStorage.getItem('option') : ''
        return option ? JSON.parse(option) : []
    })  //服务器列表
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)  //选择服务器下拉框
    const [roleInfo, setRoleInfo] = useState<Role | undefined>(() => {
        let info = localStorage.getItem('info') != null ? localStorage.getItem('info') : ''
        return info ? JSON.parse(info) : undefined
    })  //角色信息
    const [gameType, setGameType] = useState<Game>(() => {
        return roleInfo?.gameType
    })
    const [newGameType, setNewGameType] = useState<Game>(() => {
        let newGameType = localStorage.getItem('gametype') != null ? localStorage.getItem('gametype') : ''
        return newGameType ? JSON.parse(newGameType).gameType : undefined
    })

    const [flowType, setFlowType] = useState<FlowType>(FlowType.One);   //选择角色流程
    const [gameList] = useState<any>({
        [Game.Ml]: {
            id: Game.Ml,
            name: '魔力宝贝：复兴',
            text: '首款刘亦菲代言魔力宝贝手游',
            icon: require('@/assets/ml/logo.png')
        },
        [Game.Sa]: {
            id: Game.Sa,
            name: '新石器时代',
            text: '正版石器时代授权手游',
            icon: require('@/assets/sa/logo.png')
        }
    })
    const [loginOpen, setLoginOpen] = useState<boolean>(false)  //登录弹窗

    useEffect(() => {
        if (localStorage.getItem('info') && localStorage.getItem('token') && localStorage.getItem('option')) {
            // if(gameType == newGameType){
            //     setFlowType(FlowType.Two)
            // }else{
            //     login()
            // }
            setFlowType(FlowType.Two)
        }
        if (!(localStorage.getItem('token') != null)) {
            login()
        }
    }, []);

    // 点击服务器选项
    const changeServe = (serverItem: any) => {
        setServer(JSON.parse(serverItem))
    }

    // 开始充值
    const startPay = () => {
        if (!server) {
            return confirmDialog(
                'info',
                '提示',
                ['未选择服务器信息'],
                'single'
            )
        }
        localStorage.setItem('info', JSON.stringify({ ...roleInfo, ...server }))
        localStorage.setItem('server', JSON.stringify(server))
        navigate('/pay')
    }

    // 获取用户角色信息然后存储
    const getRoleInfo = (info: string) => {
        setServer(undefined)
        let option = localStorage.getItem('option') != null ? localStorage.getItem('option') : ''
        setServerOptions(option ? JSON.parse(option) : [])
        setRoleInfo(info ? JSON.parse(info) : undefined)
        setGameType(info ? JSON.parse(info).gameType : undefined)
        localStorage.setItem('info', info)  //存在session里面
        localStorage.setItem('token', JSON.parse(info).access_token)
    }

    // 登录的回调
    const loginCallBack = (data: LoginData) => {
        // 没有角色信息
        if (data.acount_list[0].infos?.length == 0) {
            confirmDialog(
                'info',
                '提示',
                ['当前暂无角色', '请前往游戏创建角色后进行充值~'],
                'single'
            )
            return
        }
        let roles: RoleOptions[] = []
        const info = { phone: data.phone, access_token: data.access_token, gameType: data.acount_list[0].gameType, pid: data.acount_list[0].pid, discount: Number(data.acount_list[0].pc_discount) }
        let isBind: string | undefined = JSON.stringify(info)   //!!!是否绑定过角色
        data.acount_list[0].infos?.map((item: Role, index: number) => {
            let values = { ...item, ...info }  //把手机号放里面
            roles.push({
                key: `${item.serverName}`,
                value: JSON.stringify(values),
                label: `${item.serverName}`
            })
            if (item.lately_paied == 1) { isBind = JSON.stringify(values) }   //获取绑定的角色信息
        });
        message(flowType === FlowType.One ? '登录成功' : '更换成功')
        setFlowType(FlowType.Two)
        localStorage.setItem('option', JSON.stringify(roles))  //存储options的信息
        localStorage.removeItem('server');
        const timestamp = Date.now();
        localStorage.setItem('loginTimestamp', timestamp.toString());
        if (isBind) return getRoleInfo(isBind)
    }

    // 登录
    const login = () => {
        setLoginOpen(true)
    }

    // 清除缓存
    const clearCache = () => {
        // localStorage.removeItem('token')
        // localStorage.removeItem('info')
        // localStorage.removeItem('server')
        // localStorage.removeItem('option')
        localStorage.clear();
    }

    const uuidv4 = () => {
        return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    // 通过token重新获取用户信息
    const getAccount = async () => {
        let data: any = {
            gameType: newGameType,
            phone: roleInfo?.phone,
            ts: dayjs().unix(),
            nonce: uuidv4(),
        }
        data.sign = sign(data)
        const res: any = await (React as any).request.get('/api/user/account', { params: data })
        if (res.code !== 0) return
        let roles: RoleOptions[] = []
        const info = { phone: roleInfo?.phone, access_token: roleInfo?.access_token, gameType: newGameType, pid: res.data.acount_list[0].pid, discount: Number(res.data.acount_list[0].pc_discount) }
        let isBind: string | undefined = JSON.stringify(info)   //!!!是否绑定过角色
        res.data.acount_list[0].infos?.map((item: Role, index: number) => {
            let values = { ...item, ...info }  //把手机号放里面
            roles.push({
                key: `${item.serverName}`,
                value: JSON.stringify(values),
                label: `${item.serverName}`
            })
            if (item.lately_paied == 1) { isBind = JSON.stringify(values) }   //获取绑定的角色信息
        });
        setServerOptions(roles)
        localStorage.setItem('option', JSON.stringify(roles))  //存储options的信息
        setRoleInfo(JSON.parse(isBind))
        localStorage.setItem('info', isBind)  //存在session里面
        setDropdownOpen(true)
    }


    return (
        <>
            {/* 更换账号 */}
            {
                (React as any).reactModal({
                    open: loginOpen,
                    onCancel: () => setLoginOpen(false),
                    width: 'auto',
                    centered: true,  //垂直居中modal
                    maskClosable: false, //点击蒙层不可以关闭弹窗
                    modalRender: () => <Login setLogin={setLoginOpen} loginCallBack={loginCallBack} gameType={flowType === FlowType.One ? newGameType : gameType} btnText={flowType === FlowType.One ? '立即登录' : '立即更换'} />,   //自定义渲染对话框
                    destroyOnClose: true,  //关闭时销毁 Modal 里的子元素
                })
            }
            <Helmet>
                <title>{newGameType == Game.Ml ? '魔力宝贝：复兴 - 充值中心' : '新石器时代 - 充值中心'}</title>
            </Helmet>
            <div className='flow-box'>
                <div className='flow-top'>
                    <img className='flow-logo' onClick={() => { navigate('/home') }} src={require('@/assets/luyou-logo.png')} alt="" />
                    <h1 className='flow-title' onClick={() => { navigate('/home') }}>支付中心</h1>
                </div>
                <div className='placeholder'></div>
                {
                    page == 'pc' ? (<>
                        <div className={`flow-banner ${newGameType == Game.Sa ? 'sa-banner' : ''} ${newGameType == Game.Ml ? 'ml-banner' : ''}`}></div>
                    </>) : null
                }
                <div className='flow'>
                    <div className='content-top'>
                        {
                            page == 'pc' ? (<>
                                <LeftOutlined className='go-back' onClick={() => {
                                    navigate('/home')
                                }} />
                            </>) : null
                        }
                        <div className='top-identification'>
                            <span className={flowType == FlowType.One || flowType == FlowType.Two ? 'circle active' : 'circle'}>1</span>
                            <i className={flowType == FlowType.Two ? 'line active' : 'line'}></i>
                            <span className={flowType == FlowType.Two ? 'circle active' : 'circle'}>2</span>
                            <i className='line'></i>
                            <span className='circle'>3</span>
                        </div>
                        <div className='top-text'>
                            <span className={flowType == FlowType.Two ? 'text active' : 'text'}>账号登录</span>
                            <span className={flowType == FlowType.Two ? 'text active' : 'text'}>选择角色</span>
                            <span className='text'>开始充值</span>
                        </div>
                    </div>
                    <div className='flow-content'>
                        <img className='icon' src={gameList[newGameType]?.icon} alt="" />
                        <div className='name'>{gameList[newGameType]?.name}</div>
                        <div className='text'>{gameList[newGameType]?.text}</div>
                        {
                            flowType == FlowType.One ? (
                                <div className='one'>
                                    <button className='btn' onClick={(e) => { e.stopPropagation(); login() }}>登录账号</button>
                                </div>
                            ) : null
                        }
                        {
                            flowType == FlowType.Two ? (
                                <div className='two'>
                                    <div className='from'>
                                        <div className='from-item'>
                                            <div className='item-label'>账号</div>
                                            <div className='item-value'>
                                                {roleInfo?.phone}
                                                <span className='change' onClick={(e) => { e.stopPropagation(); login() }}>更换</span>
                                            </div>
                                        </div>
                                        <div className='from-item'>
                                            <div className='item-label'>服务器</div>
                                            <Dropdown
                                                trigger={['click']}
                                                open={dropdownOpen}
                                                onOpenChange={(open: boolean) => { !open && setDropdownOpen(open); }}
                                                menu={{
                                                    items: serverOptions,
                                                    selectable: true,
                                                    defaultSelectedKeys: [server?.serverName],
                                                    onSelect: ({ item }: any) => { changeServe(item.props.value) },
                                                    className: "custom-menu-flow"
                                                }}
                                            >
                                                <div className='item-value' onClick={() => getAccount()}>
                                                    {server?.serverName || '选择服务器'}
                                                    <i className='icon'></i>
                                                </div>
                                            </Dropdown>
                                        </div>
                                        <div className='from-item'>
                                            <div className='item-label'>角色ID</div>
                                            <div className='item-value disabled'>
                                                {server?.roleid || '选择服务器后自动填写角色ID'}
                                            </div>
                                        </div>
                                        <div className='from-item'>
                                            <div className='item-label'>角色昵称</div>
                                            <div className='item-value disabled'>
                                                {server?.name || '选择服务器后自动填写角色昵称'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='tip-text'>*新注册账号若无服务器信息，尝试前往游戏重新登录</div>
                                    <button className='btn' onClick={(e) => { e.stopPropagation(); startPay() }}>开始充值</button>
                                    <p className='agreement'>重要提示:您登录并使用本站提供的游戏充值服务即代表</p>
                                    <p className='agreement'>
                                        <span className='text'>您同意并遵守</span>
                                        <a className='link' href='https://www.giraffar.com/web-static/ly/user.html' target='_blank'>《用户协议》</a>
                                        <span className='text'>及</span>
                                        <a className='link' href='https://www.giraffar.com/web-static/ly/privacy.html' target='_blank'>《隐私政策》</a>
                                    </p>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
                {newGameType == Game.Sa && <SaFooter />}
                {newGameType == Game.Ml && <MlFooter />}
            </div>
        </>

    )
}
