import React from 'react'
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { message, confirmDialog } from '@/utils/common';

const TIMEOUT = 50000;

const instance = axios.create({
    timeout: TIMEOUT
});

instance.interceptors.request.use(config => {
    let headers = cloneDeep(config.headers);

    if (!headers['Content-Type']) {
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
        headers['Access-Control-Allow-Origin'] = '*';
    }

    const token = localStorage.getItem('token');
    if (token) {
        headers['Authorization'] = 'Bearer ' + token;
    }

    config.headers = headers; // 设置修改后的headers

    return config;
}, err => {
    return Promise.reject(err);
});

const anewLogin = (code) => {
    localStorage.removeItem('token')
    localStorage.removeItem('info')
    localStorage.removeItem('server')
    localStorage.removeItem('option')
    code == 6000 && confirmDialog('info','提示',['登录过期，请重新登录~'],'double',()=>{window.location.reload()},()=>{window.location.reload()});
    code == 6001 && confirmDialog('info','提示',['当前账号已在其他设备登录，请重新登录~'],'double',()=>{window.location.reload()},()=>{window.location.reload()});
    code == 6006 && confirmDialog('info','提示',['登录过期，请重新登录~'],'double',()=>{window.location.reload()},()=>{window.location.reload()});
    return {code}
}

instance.interceptors.response.use(res => {
    const data = res.data;
    const code = data.code;
    if (code == 6000 || code == 6001 || code == 6006) return anewLogin(code)
    if (code !== 0) {
        // message(data.message);
        confirmDialog('info','提示',[data.message],'single')
        // Promise.reject(data); // 返回一个rejected状态的Promise，以便在调用端捕获错误
        return code
    }
    return data;
}, err => {
    // console.log('err:', err);
    if(err.response && err.response.status >= 500 ){
        confirmDialog('info','提示',['当前充值人数过多，请稍后重试'],'single')
        return err
    }
    // 这里可以处理请求的错误
    // return Promise.reject(err);
    return err
});

export default instance;
