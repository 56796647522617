/**
* 游戏分类
* @date 2024/06/12
*/
export enum Game {
    /**
    * 石器时代
    */
    Sa = '2',
    /**
    * 魔力宝贝
    */
    Ml = '3',
}

/**
* 是否首充
* @date 2024/06/13
*/
export enum FirstRechargeType {
    /**
    * 首充
    */
    Yes = 1,
    /**
    * 无首充
    */
    No = 0,
}

/**
* 平台
* @date 2024/06/13
*/
export enum PlatformType {
    /**
    * 支付宝
    */
    Zfb = '2',
    /**
    * 微信
    */
    Wx = '1',
}

/**
* 支付场景
* @date 2024/06/13
*/
export enum OrCodeType {
    /**
    * 小程序
    */
    Xcx = '3',
    /**
    * PC端
    */
    Pc = '4',
    /**
    * 移动端
    */
    Ml = '5',
}