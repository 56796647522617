import React, { useEffect } from 'react'

// 判断是什么类型,用什么less 
if (/(iPhone|iPad|iPod|iOS|Android|Windows Phone|BlackBerry|SymbianOS)/i.test(navigator.userAgent)) {
  import('./mlFooter-m.less')
} else {
  import('./mlFooter.less');
}



export default function MlFooter() {

  return (
    <div className='ml-footer'>
      <div className='footer-left'>
        <div className='footer-res'></div>
        <div className='logo'></div>
      </div>
      <div className='footer-right'>
        <div className='footer-text1'>
          <a href='https://www.giraffar.com/sa/user.html' target='_blank' > 用户协议 </a> |
          <a href='https://www.giraffar.com/sa/privacy.html' target='_blank' > 隐私政策 </a>
        </div>
        <div className='footer-text2'>
          适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活<br />
          抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当<br />
        </div>
        <div className='footer-text3'>
          <span>COPYRIGHT © 2023 上海鹿深科技有限公司</span>

          <a href="https://beian.miit.gov.cn/" target="_blank" className='footer-records'>&nbsp;&nbsp; 沪ICP备2022009975号-1</a> &nbsp;&nbsp; | &nbsp;&nbsp;
          国新出审[2023] 1212号 &nbsp;&nbsp;&nbsp;

          {/* <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014701" className='footer-records footer-records-flex'>
            <img src={require('https://sa-new-giraffar-1309429869.cos.ap-shanghai.myqcloud.com/ml-static/preorder-web/footer-icon.png')} className='footer-icon' />
            <p >&nbsp;&nbsp;沪公网安备 31011202014701号</p>
          </a><br /> */}
        </div>
        {/* <div className='footer-text3'>
          <span>联系电话：021-62961798 | 联系地址：上海市嘉定区真南路4268号2幢JT 14311室</span>
        </div> */}
        <div className='footer-text3'>
          <span>©SQUARE ENIX CHINA CO.,LTD.All Rights Reserved.</span>
        </div>

      </div>
    </div>
  )
}
