import ReactDOM from 'react-dom/client';
import MyApp from './App';
import reportWebVitals from './reportWebVitals';
import { App } from "antd";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <App >
    <MyApp />
  </App>
);

reportWebVitals();
