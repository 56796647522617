/**
* 选择角色流程
* @date 2024/06/12
*/
export enum FlowType {
    /**
    * 账号登录
    */
    One = 1,
    /**
    * 选择角色
    */
    Two = 2,
}
