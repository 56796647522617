import React, { useEffect } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { confirmDialog } from '@/utils/common';
import { Game } from "@/enum/game"

interface RequireAuthProps {
  children: JSX.Element;
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  window.scrollTo(0, 0);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location.pathname]);

  const token: any = localStorage.getItem('token');
  const info: any = localStorage.getItem('info');
  const gameType: any = localStorage.getItem('gametype');
  const server: any = localStorage.getItem('server');
  console.log('children:', children);
  console.log("User is trying to access:", location.pathname);

  const logout: any = () => {
    // 判断用户登录时长
    const loginTimestamp = localStorage.getItem('loginTimestamp');
    if (loginTimestamp != null) {
      const currentTime = Date.now();
      const timeElapsed = currentTime - parseInt(loginTimestamp);

      // 2小时 = 7200000 毫秒
      if (timeElapsed > 7200000) {
        confirmDialog(
          'info',
          '提示',
          ['登录过期，请重新登录~'],
          'single',
          () => { },
          () => {
            localStorage.clear(); // 清除所有localStorage数据
            // navigate('/home'); // 重定向到首页
          }
        )
        return true
      } else {
        return false
      }
    }
  }

  if (location.pathname === '/') {
    return <Navigate to="/home" replace />;
  }

  if (location.pathname === '/home') {
    // if (token != null) {
    //   if (server) {
    //     return <Navigate to="/pay" replace />;
    //   } else {
    //     return <Navigate to="/flow" replace />;
    //   }
    // } else {
    //   localStorage.clear(); // 清除所有localStorage数据
    //   return children;
    // }
    return children;
  }

  if (location.pathname === '/ml') {
    localStorage.setItem('gametype', JSON.stringify({ gameType: Game.Ml }))
    const newGameType = Game.Ml
    if (server != null) {
      if (newGameType == JSON.parse(info)?.gameType) {
        if (logout()) {
          localStorage.setItem('gametype', JSON.stringify({ gameType: Game.Ml }))
          return <Navigate to="/ml" replace />;
        } else {
          return <Navigate to="/pay" replace />;
        }
      } else {
        return children;
      }
    } else {
      return children;
    }
  }

  if (location.pathname === '/sa') {
    localStorage.setItem('gametype', JSON.stringify({ gameType: Game.Sa }))
    const newGameType = Game.Sa
    if (server != null) {
      if (newGameType == JSON.parse(info)?.gameType) {
        if (logout()) {
          localStorage.setItem('gametype', JSON.stringify({ gameType: Game.Sa }))
          return <Navigate to="/sa" replace />;
        } else {
          return <Navigate to="/pay" replace />;
        }
      } else {
        return children;
      }
    } else {
      return children;
    }
  }

  if (location.pathname === '/flow') {
    if (server != null) {
      if (JSON.parse(gameType)?.gameType == JSON.parse(info)?.gameType) {
        if (logout()) {
          return <Navigate to="/home" replace />;
        } else {
          return <Navigate to="/pay" replace />;
        }
      } else {
        return children;
      }
    } else {
      if (token != null || gameType != null) {
        if (logout()) {
          return <Navigate to="/home" replace />;
        } else {
          return children;
        }
      } else {
        localStorage.clear(); // 清除所有localStorage数据
        return <Navigate to="/home" replace />;
      }
    }
  }

  if (location.pathname === '/pay') {
    if (logout()) {
      return <Navigate to="/home" replace />;
    }
    if (token != null && server != null) {
      return children;
    } else {
      localStorage.clear(); // 清除所有localStorage数据
      return <Navigate to="/home" replace />;
    }
  }

  return children;
};