import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import PcPay from '@/components/pcPay/pcPay';
import MtPay from '@/components/mtPay/mtPay';


export default function Pay() {
  const navigate = useNavigate()
  const [page, setPage] = useState(() => (React as any).pageType);   //用来区分不同的端
  
  // const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'); // 区分横屏还是竖屏

  // useEffect(() => {
  //   const handleResize = () => {
  //     console.log(window.innerWidth > window.innerHeight);
      
  //     // 检查屏幕宽度是否大于高度
  //     setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
  //   };

  //   // 添加事件监听器
  //   window.addEventListener('resize', handleResize);

  //   // 组件卸载时移除事件监听器
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  
  return (
    <>
    {
      (page === 'pc') ? (
        <PcPay />
      ) : (
        <MtPay />
      )
    }
    </>
  )
}
