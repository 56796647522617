import { getQueryVariable } from '@/utils/common';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import {message} from '@/utils/common';


if (/(iPhone|iPad|iPod|iOS|Android|Windows Phone|BlackBerry|SymbianOS)/i.test(navigator.userAgent)) {
  import('./login-m.less');
} else {
  import('./login.less');
}

interface AProps {
  setLogin: (value: boolean) => void;   //登录
  gameType: string   //游戏的ID 2：石器， 3：魔力
  loginCallBack:any
  btnText: string
}

export default function Login({ setLogin, loginCallBack, gameType, btnText }: AProps) {
  const [page, setPage] = useState(() => (React as any).pageType);   //用来区分不同的端
  const [form] = Form.useForm();  //form表单
  const [codeBtn, setCodeBtn] = useState<number | string>('获取验证码')
  const phone = Form.useWatch('phone', form);  //监听电话
  const [timer, setTimer] = useState<any>()  //定时器
  const [checkboxPolicy, setCheckboxPolicy] = useState(false)   //是否点击了同意协议 false没有 ， true有
  const [loading, setLoading] = useState(false)  //loading


  // 获取验证码
  let codeNum: number = 60
  const getCode = async () => {
    let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    let mobileReg = reg.test(phone)

    if (!mobileReg) {
      message('请输入正确的11位手机号格式')
      return
    }


    // 发送验证码的请求接口
    try {
      const res: any = await (React as any).request.post('/api/sms/send', { phone: phone, gameType })
      if (res.code !== 0) return

      setCodeBtn(codeNum)
      const time = setInterval(() => {
        codeNum--
        setCodeBtn(codeNum)
        if (codeNum == 0) {
          clearInterval(time)
          setCodeBtn('获取验证码')
        }
      }, 1000)
      setTimer(time)
      return () => clearInterval(time);
    } catch (error) {

    }
  }


  interface finishValues {
    phone: string,  //手机号
    code: string,  //验证码
  }
  const onFinish = async (values: finishValues) => {
    if (!checkboxPolicy) return message('请先同意用户协议和隐私协议')
    let data = {
      phone: values.phone,
      code: values.code,
      gameType: gameType
    }

    setLoading(true)

    try {
      const res: any = await (React as any).request.post(`/api/user/login`, data)
      if (res.code != 0) return setLoading(false)
      setLoading(false)
      setLogin(false)
      loginCallBack(res.data)


    } catch (error) {

    }
  }


  return (
    <div className='pop login-pop'>
      <div className='login-cont'>
        <p className='luyou-logo'></p>
        <>
          <Form
            name="basic"
            labelCol={{
              flex: '20%',
            }}
            wrapperCol={{
              flex: 1,
            }}
            initialValues={{
              // 'phone': ''
            }}
            preserve={false}  //弹窗关闭之后是否保留里面输入内容
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            className='luyou-login-form'
          >
            <Form.Item
              label="手机号"
              className='ant-input-style'
              name="phone"
              rules={[
                {
                  required: true,
                  message: '请输入正确的手机号',
                  pattern: new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/, 'g'),
                },
              ]}
            >
              <Input
                maxLength={11}
                size="large"
                placeholder='请输入手机号'
                variant={'borderless'}
              />
            </Form.Item>

            <Form.Item
              label="验证码"
              className='ant-input-style'
              name="formCode"
              wrapperCol={{
                flex: '1',
              }}
              rules={[
                {
                  required: true,
                  message: '请输入4位纯数字长度的验证码',
                  pattern: new RegExp(/^\d{4}$/, "g")
                },
              ]}

            >
              <Row gutter={0}>
                <Col flex={1} >
                  <Form.Item
                    name="code"
              
                    noStyle
                  >
                    <Input
                      maxLength={4}
                      variant={'borderless'}
                      size="large"
                      placeholder='请输入验证码'
                    />
                  </Form.Item>
                </Col>
                <Col className='ant-code-style'>
                  <p
                    className={`code-btn ${codeBtn == '获取验证码' ? 'code-btn-gray' : ''}`}
                    onClick={() => codeBtn == '获取验证码' && getCode()}
                  >{codeBtn}</p>
                </Col>
              </Row>
            </Form.Item>


            <Form.Item className='ant-btn-style'>
              <Button htmlType="submit" type='text' className={`login-sumbit`} loading={loading}>
                {btnText}
              </Button>
            </Form.Item>
          </Form>

        </>

        <p className='radio-agreement'>
          <Checkbox onChange={(e) => { setCheckboxPolicy(e.target.checked) }}>
            <span className='text'>我已阅读并同意</span>
            <a href='https://www.giraffar.com/sa/user.html' target='_blank'>《鹿游用户协议》</a>
            <a href='https://www.giraffar.com/sa/privacy.html' target='_blank'>《鹿游用户隐私政策》</a>
          </Checkbox>
        </p>


        <p className='close' onClick={() => { setLogin(false) }}></p>

      </div>

    </div>
  )
}
