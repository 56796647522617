import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";

import { Game } from "@/enum/game"
import { FlowType } from "@/enum/flow"
import LyFooter from '../../components/lyFooter/lyFooter';

if (/(iPhone|iPad|iPod|iOS|Android|Windows Phone|BlackBerry|SymbianOS)/i.test(navigator.userAgent)) {
  import('./home-m.less');
} else {
  import('./home.less');
}
import { Helmet } from 'react-helmet';

export default function Home() {
  const navigate = useNavigate()
  const [page, setPage] = useState(() => (React as any).pageType);   //用来区分不同的端
  const [gameList] = useState<any>([
    {
      id: Game.Ml,
      name: '魔力宝贝：复兴',
      text: ['首款刘亦菲代言魔力宝贝手游', '首次充值额外赠送55%'],
      icon: require('@/assets/ml/logo.png'),
      isNew: true
    },
    {
      id: Game.Sa,
      name: '新石器时代',
      text: ['正版石器时代授权手游', '首次充值额外赠送55%'],
      icon: require('@/assets/sa/logo.png'),
      isNew: false
    }
  ])
  const [gameType, setGameType] = useState<Game>(Game.Ml)

  // 登录
  const login = (gameType: Game) => {
    setGameType(gameType)

    const token = localStorage.getItem('token');
    const info = localStorage.getItem('info');
    const server = localStorage.getItem('server');
    const user = info ? JSON.parse(info) : undefined

    if (gameType == user?.gameType) {
      if (token != null && info != null && server != null) {
        navigate('/pay')
        return
      }
      localStorage.setItem('gametype', JSON.stringify({ gameType: gameType }))
      navigate('/flow')
    } else {
      // localStorage.clear();
      // localStorage.setItem('info', JSON.stringify({ gameType: gameType }))  //存在session里面
      localStorage.setItem('gametype', JSON.stringify({ gameType: gameType }))
      navigate('/flow')
    }
  }

  return (
    <>
      <Helmet>
        <title>鹿游 - 充值中心</title>
      </Helmet>
      <div className='home'>
        <div className='home-top'>
          <img className='home-logo' src={require('@/assets/luyou-logo.png')} alt="" />
          <h1 className='home-title'>支付中心</h1>
        </div>
        <div className='placeholder'></div>
        <div className='home-banner'></div>
        <div className='home-box'>
          {
            page === 'pc' ? null : (
              <h1 className="title">
                充值中心
              </h1>)
          }
          {
            gameList.map((item: any) => {
              return (
                <div className={item.isNew ? 'game-item new' : 'game-item'} key={item.id}>
                  <img className='game-icon' src={item.icon} />
                  <div className='left'>
                    <div className='name-box'>
                      <div className='game-name'>
                        {item.name}
                      </div>
                      {
                        item.text.map((text: string) => {
                          return (
                            <div className='game-text' key={text}>
                              {text}
                            </div>
                          )
                        })
                      }
                    </div>
                    {/* navigate(`/pay/${item.id}`) */}
                    <button className='game-btn' onClick={(e) => { e.stopPropagation(); login(item.id) }}>充值</button>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <LyFooter />
    </>
  )
}
