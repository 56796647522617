import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown, InputNumber } from 'antd';
import type { InputNumberProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Login from '@/components/login/login';
import dayjs from 'dayjs';
import('./mtPay.less');

import { LoginData, Role, RoleOptions, Product, PayInfoData, PayInfo } from '@/utils/type'
import { Game, FirstRechargeType, PlatformType, OrCodeType } from "@/enum/game"
import { message, confirmDialog, sign, formatNumber, maskPhoneNumber } from '@/utils/common'
import SaFooter from '../../components/saFooter/saFooter';
import MlFooter from '../../components/mlFooter/mlFooter';
import { Helmet } from 'react-helmet';

let clearTimer = false
let orderTimer: number | undefined = undefined
export default function MtPay() {
  const navigate = useNavigate()
  const [page, setPage] = useState(() => (React as any).pageType);   //用来区分不同的端

  const [server, setServer] = useState<Role>(() => {
    let server = localStorage.getItem('server') != null ? localStorage.getItem('server') : ''
    return server ? JSON.parse(server) : undefined
  }) //用户选择的服务器信息
  const [serverOptions, setServerOptions] = useState<any>(() => {
    let option = localStorage.getItem('option') != null ? localStorage.getItem('option') : ''
    return option ? JSON.parse(option) : undefined
  })  //服务器列表
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)  //选择服务器下拉框
  const [roleInfo, setRoleInfo] = useState<Role | undefined>(() => {
    let info = localStorage.getItem('info') != null ? localStorage.getItem('info') : ''
    return info ? JSON.parse(info) : undefined
  })  //角色信息
  const [gameType, setGameType] = useState<Game>(() => {
    return roleInfo?.gameType
  })
  const [gameList] = useState<any>({
    [Game.Ml]: {
      id: Game.Ml,
      name: '魔力宝贝：复兴',
      text: '首款刘亦菲代言魔力宝贝手游',
      icon: require('@/assets/ml/logo.png')
    },
    [Game.Sa]: {
      id: Game.Sa,
      name: '新石器时代',
      text: '正版石器时代授权手游',
      icon: require('@/assets/sa/logo.png')
    }
  })
  const [loginOpen, setLoginOpen] = useState<boolean>(false)  //登录弹窗
  const [isInstructionsRender, setIsInstructionsRender] = useState<boolean>(false) //充值说明弹框
  const [productList, setProductList] = useState<Product[]>([]) // 商品信息列表
  const [isSelectProduct, setIsSelectProduct] = useState<boolean>(false) // 选中的商品弹框
  const [selectProduct, setSelectProduct] = useState<Product | any>({
    amount: 0.01,
    firstRecharge: 1,
    gold: 80,
    id: 0,
    img: " https://lyb.giraffar.com/pc/pay/ml_icon_01.png",
    productName: "80钻石充值",
    quantity: 1,
    totalPrice: 0.01,
    totalGold: 0
  }) // 选中的商品
  const [discount, setDiscount] = useState<number>(() => {
    return roleInfo?.discount ? (roleInfo.discount * 0.01) : 1
  })  // 折扣
  const [payLoad, setPayLoad] = useState(false)   //立即支付节流限制
  const [qrTimer, setQrTimer] = useState<number | undefined>()   //两分钟后定时器过期
  const [platform, setPlatform] = useState<PlatformType>(PlatformType.Zfb)  //平台

  useEffect(() => {
    getPaylist()
    clearTimeout(orderTimer)   //关闭轮询定时器
    clearTimeout(qrTimer)   //关闭两分钟后过期的定时器
    if (localStorage.getItem('id') != null && page != 'pc') {
      let id: string | null = localStorage.getItem('id') != null ? localStorage.getItem('id') : ''
      console.log('id:', id);
      timingQueryOrder(id)   //轮询任务
      timerStop()
    }
    return () => {
      localStorage.removeItem('id')
      localStorage.removeItem('url')
    }
  }, [])

  useEffect(() => {
    if (isSelectProduct) {
      setSelectProduct((value: any) => {
        return { ...value, totalGold: getTotalGold() }
      })
    }
  }, [selectProduct.id, selectProduct.quantity, isSelectProduct])

  // 充值说明
  const instructionsRender = () => {
    return (
      <div className='pop pop-instructions'>
        <p className='close' onClick={() => { setIsInstructionsRender(false) }}></p>
        <p className='title'>充值说明</p>
        <div className='text-box'>

          <p className='text'>
            <span className='serial-number'>1.&ensp;</span>
            {/* 魔力：明星；石器：天降 */}
            <span className='text'>充值金额都计算到游戏内累充、限时累充、{gameType == Game.Ml ? '明星' : '天降'}福利、财源滚滚活动，以及可获得对应鹿游宝成长值和积分。</span>
          </p>
          <p className='text'>
            <span className='serial-number'>2.&ensp;</span>
            <span className='text'>首次充值任意档位可享受赠送55%绑钻（可购买数量为1），首充奖励以每个账号（包括支付宝鹿游游戏中心）享受一次。</span>
          </p>
          <p className='text'>
            <span className='serial-number'>3.&ensp;</span>
            <span className='text'>再次充值仅888元档位可固定享受赠送15%绑钻。</span>
          </p>
          <p className='text'>
            <span className='serial-number'>4.&ensp;</span>
            <span className='text'>充值888元钻石还可获得720公会贡献和720魅力值。</span>
          </p>
          <p className='text'>
            <span className='serial-number'>5.&ensp;</span>
            <span className='text'>充值完成后，前往游戏内查收钻石邮件，邮件有可能存在网络延迟的情况，如未到账请稍后再查看，钻石邮件需在30天内领取。</span>
          </p>
        </div>
      </div>
    )
  }


  const onChangeNum: InputNumberProps['onChange'] = (num) => {
    const quantity: number = num ? Number(num) : 1
    const totalPrice = ((quantity * selectProduct.amount) * discount).toFixed(2)

    setSelectProduct((value: any) => {
      return { ...value, quantity, totalPrice }
    })
  };

  // 充值抽屉弹窗
  const selectGoodsRender = (
    <div className='drawer-select-product'>
      <div className="product-item-box">
        <div className='product-item'>
          {
            selectProduct.firstRecharge === FirstRechargeType.Yes && (<span className='first-recharge-icon'></span>)
          }
          <div className='item-left'>
            <img className='product-img' src={selectProduct.img} alt={selectProduct.productName} onError={(event: any) => {
              event.currentTarget.src = fallbackImage(selectProduct.gold);
            }} />
            <div className='product-info'>
              <div className='product-gold'>
                <i className='diamond-icon'></i><span className='gold'>{formatNumber(selectProduct.gold)}</span>
              </div>
              <div className='product-first-recharge'>
                {
                  selectProduct.firstRecharge === FirstRechargeType.Yes ? (
                    <>
                      <span className='first-recharge'>首充赠送</span><i className='diamond-lock-icon'></i><span className='first-recharge'>55%</span>
                    </>
                  ) : (
                    <>
                      {
                        selectProduct.gold == 8880 ? (
                          <>
                            <span className='first-recharge'>赠送</span><i className='diamond-lock-icon'></i><span className='first-recharge'>15%</span>
                          </>
                        ) : null
                      }
                    </>
                  )
                }
              </div>
            </div>
          </div>
          <div className='product-amount'><span className='rmb'></span>{selectProduct.amount}</div>
        </div>
        <div className='select-num'>
          <span className='text'>购买数量</span>
          <div className='num-box'>
            <div className="jia-jian" onClick={() => { changePayNumber((selectProduct.firstRecharge === FirstRechargeType.Yes ? 1 : 99), 'jian') }}>
              <i className='jian'></i>
            </div>
            <InputNumber
              className='num-input'
              min={1}
              max={selectProduct.firstRecharge === FirstRechargeType.Yes ? 1 : 99}
              value={selectProduct.quantity}
              controls={false}
              precision={0}
              onChange={onChangeNum}
            />
            <div className="jia-jian" onClick={() => { changePayNumber((selectProduct.firstRecharge === FirstRechargeType.Yes ? 1 : 99), 'jia') }}>
              <i className='jia'></i>
            </div>
          </div>
        </div>
        <div className='total-price'>总价：<i className='rmb'></i><span className='price'>{formatNumber(selectProduct.totalPrice)}</span></div>
        <div className='serve-name'>
          为{server?.serverName}服务器的
          <span className='name'>{server?.name}</span>
          充值{formatNumber(selectProduct.totalGold)}钻石
        </div>
      </div>

      <div className='platform-box'>
        <div className={`platform-box-item ${platform === PlatformType.Zfb ? 'active' : ''}`} onClick={
          platform === PlatformType.Zfb ? () => { } : () => {
            setPlatform(PlatformType.Zfb)
          }
        }>
          <div className='left'>
            <i className='zfb-icon'></i>
            <span className='text'>支付宝</span>
            <em className='mask'>推荐</em>
          </div>
          <i className='right'></i>
        </div>
        {/* <div className={`platform-box-item ${platform === PlatformType.Wx ? 'active' : ''}`} onClick={
          platform === PlatformType.Wx ? () => { } : () => {
            setPlatform(PlatformType.Wx)
          }
        }>
          <div className='left'>
            <i className='wx-icon'></i>
            <span className='text'>微信</span>
          </div>
          <i className='right'></i>
        </div> */}
      </div>

      <button className='pay-btn' onClick={(e) => { e.stopPropagation(); payment() }}>立即支付</button>
    </div>
  )

  // 获取商品信息
  const getPaylist = async () => {
    const data: any = {
      roleId: server?.roleid,
      gameType,
      ts: dayjs().unix()
    }
    data.sign = sign(data)
    const res: any = await (React as any).request.post('/api/user/get_product_config', data)
    if (res.code !== 0) return
    const list = res.data.map((item: Product) => {
      return {
        ...item,
        img: `https://www.giraffar.com/web-static/${item.img}`
      }
    })
    console.log('list:', list);

    setProductList(list)
  }

  // 登录的回调
  const loginCallBack = (data: LoginData) => {
    // 没有角色信息
    if (data.acount_list[0].infos?.length == 0) {
      confirmDialog(
        'info',
        '提示',
        ['当前暂无角色', '请前往游戏创建角色后进行充值~'],
        'single'
      )
      return
    }
    let roles: RoleOptions[] = []
    const info = { phone: data.phone, access_token: data.access_token, gameType: data.acount_list[0].gameType, pid: data.acount_list[0].pid, discount: Number(data.acount_list[0].pc_discount) }
    let isBind: string | undefined = JSON.stringify(info)   //!!!是否绑定过角色
    data.acount_list[0].infos?.map((item: Role, index: number) => {
      let values = { ...item, ...info }  //把手机号放里面
      roles.push({
        key: `${item.serverName}`,
        value: JSON.stringify(values),
        label: `${item.serverName}`
      })
      if (item.lately_paied == 1) { isBind = JSON.stringify(values) }   //获取绑定的角色信息
    });
    clearCache()
    localStorage.setItem('gametype', JSON.stringify({ gameType: gameType }))
    localStorage.setItem('option', JSON.stringify(roles))  //存储options的信息
    const timestamp = Date.now();
    localStorage.setItem('loginTimestamp', timestamp.toString());
    localStorage.setItem('info', isBind)  //存在session里面
    localStorage.setItem('token', JSON.parse(isBind).access_token)
    navigate('/flow')
  }

  // 切换账号
  const changeAccount = () => {
    // confirmDialog('info', '提示', ['支付页面切换账号需要退出登录，', '请问需要退出登录吗？'], 'double', () => { clearCache(); navigate('/home') })
    setLoginOpen(true)
  }

  // 配置暂时默认商品图片
  const fallbackImage = (gold: number) => {
    let fallbackImage = require('@/assets/product-1.png');
    if (gold === 80) {
      fallbackImage = require('@/assets/product-1.png');
    } else if (gold === 880) {
      fallbackImage = require('@/assets/product-2.png');
    } else if (gold === 8880) {
      fallbackImage = require('@/assets/product-3.png');
    }
    return fallbackImage
  }

  // 清除缓存
  const clearCache = () => {
    // localStorage.removeItem('token')
    // localStorage.removeItem('info')
    // localStorage.removeItem('server')
    // localStorage.removeItem('option')
    // localStorage.removeItem('id')
    // localStorage.removeItem('url')
    localStorage.clear();
    clearTimeout(orderTimer)
  }

  // 切换服务器信息
  const changeServe = (serverItem: any) => {
    setServer(JSON.parse(serverItem))
    localStorage.setItem('server', serverItem)
  }

  // 通过token重新获取用户信息
  const getAccount = async () => {
    let data: any = {
      gameType,
      phone: roleInfo?.phone,
      ts: dayjs().unix(),
      nonce: uuidv4(),
    }
    data.sign = sign(data)
    const res: any = await (React as any).request.get('/api/user/account', { params: data })
    if (res.code !== 0) return
    let roles: RoleOptions[] = []
    const info = { phone: roleInfo?.phone, access_token: roleInfo?.access_token, gameType: res.data.acount_list[0].gameType, pid: res.data.acount_list[0].pid, discount: Number(res.data.acount_list[0].pc_discount) }
    let isBind: string | undefined = JSON.stringify(info)   //!!!是否绑定过角色
    res.data.acount_list[0].infos?.map((item: Role, index: number) => {
      let values = { ...item, ...info }  //把手机号放里面
      roles.push({
        key: `${item.serverName}`,
        value: JSON.stringify(values),
        label: `${item.serverName}`
      })
      if (item.lately_paied == 1) { isBind = JSON.stringify(values) }   //获取绑定的角色信息
    });
    setServerOptions(roles)
    localStorage.setItem('option', JSON.stringify(roles))  //存储options的信息
    setRoleInfo(JSON.parse(isBind))
    localStorage.setItem('info', isBind)  //存在session里面
    setDropdownOpen(true)
  }

  // 获取总钻石数目
  const getTotalGold = () => {
    let totalGold = selectProduct.gold * selectProduct.quantity
    return totalGold
  }

  // 购买数量加减
  const changePayNumber = (max: number, type: string) => {
    let quantity = selectProduct.quantity
    if (type === 'jia') {
      if (selectProduct.quantity >= max) return message('商品已达到每单最大的购买限制')
      quantity++
    } else if (type === 'jian') {
      if (selectProduct.quantity <= 1) return message('商品已达到每单最小的购买限制')
      quantity--
    }
    setSelectProduct((value: any) => {
      return { ...value, quantity, totalPrice: quantity * value.amount }
    })
  }

  //立即支付调用
  const payment = () => {
    if (payLoad) return
    setPayLoad(true)
    payInfoApi()
  }

  const uuidv4 = () => {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  // 查询的支付信息
  const payInfoApi = async () => {
    clearTimeout(orderTimer)   //关闭轮询定时器
    clearTimeout(qrTimer)   //关闭两分钟后过期的定时器
    clearTimer = false

    try {
      let data: PayInfoData = {
        gameType: gameType,
        serverId: server?.serverId,
        roleid: server?.roleid,
        productId: selectProduct.id.toString(),
        productDesc: selectProduct.productName,
        quantity: selectProduct.quantity.toString(),
        platform: platform,
        orCode: OrCodeType.Ml,
        nonce: uuidv4(),
        ts: dayjs().unix(),
      }
      data.sign = sign(data)
      const res: any = await (React as any).request.post(`/api/order/create`, data)
      if (res.code != 0) return setPayLoad(false)
      if (page != 'pc') {
        const divForm = document.getElementsByTagName("div");
        if (divForm.length) {
          document.body.removeChild(divForm[0]);
        }
        const div = document.createElement("div");
        div.innerHTML = res.data.invokeStr; // data就是接口返回的form 表单字符串
        document.body.appendChild(div);
        document.forms[0].setAttribute("target", "_self"); // 新开窗口跳转
        document.forms[0].submit();
        document.forms[0].remove();
        localStorage.setItem('id', res.data.orderId)

        let timer = setTimeout(() => {
          clearTimeout(timer)
          location.reload();   //刷新
        }, 1000); // 这里的延迟时间可以根据实际情况进行调整
        return
      }

    } catch (error) {

    }
  }

  // 订单任务轮询
  const timingQueryOrder = (orderId: string | null) => {
    if (!orderId) return
    let ordTimer: any = setTimeout(() => {
      if (clearTimer) return clearTimeout(ordTimer)
      payQuery({ orderId })
      clearTimeout(ordTimer)
    }, 2000)
    orderTimer = ordTimer
  }

  // 订单查询 
  const payQuery: any = async ({ orderId }: { orderId: string }) => {
    if (!orderId) return
    let data: any = {
      gameType: gameType,
      orderId: orderId,
      nonce: uuidv4(),
      ts: dayjs().unix(),
    }
    data.sign = sign(data)
    const res: any = await (React as any).request.get(`/api/order/query`, { params: data })
    if (res.code != 0) return
    // 支付成功
    if (res.data.order_status == 10) {
      clearTimer = false
      clearTimeout(qrTimer)   //关闭两分钟后过期的定时器
      setIsSelectProduct(false)  //关闭立即支付的弹窗
      getPaylist()
      confirmDialog('success', '支付成功', ['可前往游戏邮件内领取商品'], 'single');
      localStorage.removeItem('id')
      return
    } else if (res.data.order_status == 0) {
    } else {
      clearTimer = false
      clearTimeout(qrTimer)   //关闭两分钟后过期的定时器
      confirmDialog('error', '支付失败', ['点击返回上一级充值页面'], 'single', () => { }, () => {
        payment()
      });
      return
    }
    timingQueryOrder(orderId)
  }

  const timerStop = () => {
    let qrTimer: any = setTimeout(() => {
      clearTimer = true
      clearTimeout(qrTimer)   //关闭两分钟定时器
      localStorage.removeItem('id')
    }, 120000)
    setQrTimer(qrTimer)
  }

  return (
    <>
      {/* 更换账号 */}
      {
        (React as any).reactModal({
          open: loginOpen,
          onCancel: () => setLoginOpen(false),
          width: 'auto',
          centered: true,  //垂直居中modal
          maskClosable: false, //点击蒙层不可以关闭弹窗
          modalRender: () => <Login setLogin={setLoginOpen} loginCallBack={loginCallBack} gameType={gameType} btnText={'切换账号'} />,   //自定义渲染对话框
          destroyOnClose: true,  //关闭时销毁 Modal 里的子元素
        })
      }
      {/* 充值说明弹窗 */}
      {
        (React as any).reactModal({
          open: isInstructionsRender,
          onCancel: () => setIsInstructionsRender(false),
          width: 'auto',
          centered: true,  //垂直居中modal
          modalRender: instructionsRender,   //自定义渲染对话框
          destroyOnClose: false,  //关闭时销毁 Modal 里的子元素
        })
      }

      {/* 充值抽屉弹窗 */}
      {
        (React as any).reactDrawer({
          open: isSelectProduct,
          onClose: () => {
            confirmDialog('info', '提示', ['确定放弃支付？'], 'double', () => { setIsSelectProduct(false) }, () => { })
          },
          width: 'auto',
          content: selectGoodsRender,   //自定义渲染对话框
          placement: 'bottom',
          maskClosable: true,
          className: 'drawer-box'
        })
      }
      <Helmet>
        <title>{gameType == Game.Ml ? '魔力宝贝：复兴 - 充值中心' : '新石器时代 - 充值中心'}</title>
      </Helmet>
      <div className='pay-ml'>
        <div className='pay-top'>
          <div className="top">
            <div className='top-left'>
              <img className='pay-logo' src={require('@/assets/luyou-logo.png')} alt="" onClick={() => { navigate('/home') }} />
              <h1 className='pay-title' onClick={() => { navigate('/home') }}>支付中心</h1>
            </div>
            <div className='top-right'>
              <div className='top-phone'>
                {maskPhoneNumber(server?.phone)}
                <DownOutlined className='icon' />
              </div>
              <div className='top-phone logout' onClick={() => {
                confirmDialog('info', '提示', ['确定需要退出登录吗？'], 'double', () => { clearCache(); navigate('/home') })
              }}>
                退出登录
              </div>
            </div>
          </div>
        </div>
        <div className='placeholder'></div>
        <div className="pay-content">
          <div className={`account-box ${gameType == Game.Sa ? 'sa-bg' : 'ml-bg'}`}>
            <div className='game-info'>
              <img className='game-icon' src={gameList[gameType].icon} alt={gameList[gameType].name} />
              <div className='info'>
                <div className='game-name'>
                  {gameList[gameType].name}
                </div>
                <div className='game-text'>
                  {gameList[gameType].text}
                </div>
              </div>
            </div>
            <div className='account text' onClick={() => { changeAccount() }}>
              充值账号：<span className='text'>{server?.phone}</span>
            </div>

            <div className='text'>
              <Dropdown
                trigger={['click']}
                open={dropdownOpen}
                onOpenChange={(open: boolean) => { !open && setDropdownOpen(open); }}
                menu={{
                  items: serverOptions,
                  selectable: true,
                  defaultSelectedKeys: [server?.serverName],
                  onSelect: ({ item }: any) => { changeServe(item.props.value) },
                  className: "custom-menu-pay"
                }}
              >
                <span onClick={() => getAccount()}>服务器：<span className='server_text'>{server?.serverName}</span></span>
              </Dropdown>
            </div>
            <div className='text'>
              角色ID：{server?.roleid}
            </div>
            <div className='text'>
              角色昵称：{server?.name}
            </div>
          </div>
          <div className='product-box'>
            <div className='title'>选择商品 <span className='instructions' onClick={() => { setIsInstructionsRender(true) }}>充值说明</span></div>
            <div className='admonish'>所有充值金额全部计入游戏内累计充值活动及鹿游宝成长值和积分，支付完成请前往游戏内查收钻石邮件</div>
            <div className='product-list'>
              {
                productList.map((product: Product) => {
                  return (
                    <div className='product-item' key={product.id} onClick={() => {
                      setSelectProduct(() => {
                        return { ...product, quantity: 1, totalPrice: product.amount }
                      });
                      setIsSelectProduct(true)
                    }}>
                      {
                        product.firstRecharge === FirstRechargeType.Yes && (<span className='first-recharge-icon'></span>)
                      }
                      <div className='item-left'>
                        <img className='product-img' src={product.img} alt={product.productName} onError={(event: any) => {
                          event.currentTarget.src = fallbackImage(product.gold);
                        }} />
                        <div className='product-info'>
                          <div className='product-gold'>
                            <i className='diamond-icon'></i><span className='gold'>{formatNumber(product.gold)}</span>
                          </div>
                          <div className='product-first-recharge'>
                            {
                              product.firstRecharge === FirstRechargeType.Yes ? (
                                <>
                                  <span className='first-recharge'>首充赠送</span><i className='diamond-lock-icon'></i><span className='first-recharge'>55%</span>
                                </>
                              ) : (
                                <>
                                  {
                                    product.gold == 8880 ? (
                                      <>
                                        <span className='first-recharge'>赠送</span><i className='diamond-lock-icon'></i><span className='first-recharge'>15%</span>
                                      </>
                                    ) : null
                                  }
                                </>
                              )
                            }
                          </div>
                        </div>
                      </div>
                      <div className='product-amount'><span className='rmb'></span>{product.amount}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        {gameType == Game.Sa && <SaFooter />}
        {gameType == Game.Ml && <MlFooter />}
      </div>
    </>
  )
}
