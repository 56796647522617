import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter, HashRouter } from "react-router-dom";
import { RequireAuth } from '@/utils/requireAuth'
import { ConfigProvider, Row, Col, Spin, App } from "antd";
import './common.less'

if (/(iPhone|iPad|iPod|iOS|Android|Windows Phone|BlackBerry|SymbianOS)/i.test(navigator.userAgent)) {
  import('./common-m.less');
}

// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import {
  px2remTransformer,
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from "@ant-design/cssinjs";
import 'dayjs/locale/zh-cn';
import "antd/dist/reset.css";
import request from "@/utils/request"; //全局加载方法
import { pageType } from '@/utils/common'; //公共方法组件
import { reactDrawer, reactModal } from '@/components/modal/modal'; //弹窗
// 当从节点模块导入Swiper自定义元素时，我们需要手动注册它。它应该只执行一次，并且它会全局注册 Swiper 自定义元素。
import { register } from 'swiper/element/bundle';
import SaPay from './controller/saPay/saPay';
import Home from './controller/home/home';
import Flow from './controller/flow/flow';
import Pay from './controller/pay/pay';


dayjs.locale('zh-cn');

/* // @ts-ignore  忽略错误 */
// 全局loding
const loading = (
  <Row justify="center" align="middle" style={{ height: "100vh", background: '#00000020' }}>
    <Col>
      <Spin size="large"></Spin>
    </Col>
  </Row>
);

// rem 适配
const px2rem = px2remTransformer({
  rootValue: 100, // 32px = 1rem; @default 16
});


const PageNotFound = React.lazy(() => import("@/components/pageNotFound/pageNotFound")); /* 空路由页面 */



// 页面rem适配
const changeSize = () => {
  if (/(iPhone|iPad|iPod|iOS|Android|Windows Phone|BlackBerry|SymbianOS)/i.test(navigator.userAgent)) {
    var stdw = 1080;
    var stdh = 1921;
    const baseSize = 100

    var docEl = document.documentElement,
      resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
      recalc = function () {
        var clientWidth = docEl.clientWidth;
        var clientHeight = docEl.clientHeight;
        if (!clientWidth) return;
        let scale = Math.min(clientWidth / stdw)
        if (window.orientation === 180 || window.orientation === 0) { scale = Math.min(clientWidth / stdw) }  //竖屏
        if (window.orientation === 90 || window.orientation === -90) { scale = Math.min(clientWidth / stdw, clientHeight / stdh) }   //横屏
        let size = (baseSize * Math.min(scale, 2)) < 35 ? 35 : (baseSize * Math.min(scale, 2))
        docEl.style.fontSize = size + 'px';

      };
    if (!document.addEventListener) return;
    recalc();
    window.addEventListener(resizeEvt, recalc, false);
    document.addEventListener('DOMContentLoaded', recalc, false);
    return
  }




  // 设置1rem 等于多少 px
  const baseSize = 100
  // 当前页面宽度和高度相对于 1920x1080的缩放比例，取两者中较小的一个作为缩放比例。
  const scale = Math.min(
    document.documentElement.clientWidth / 2560,
    document.documentElement.clientHeight / 1440
  )

  // window.screen.width  获取电脑分辨率的宽 ，如果大于1920的分辨率最小只能缩小到70px ， 1920以下的可以缩小到40px
  let w = window.screen.width > 1920 ? 75 : 40

  // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
  let size = (baseSize * Math.min(scale, 4)) < w ? w : (baseSize * Math.min(scale, 4))
  // let size = baseSize * Math.min(scale, 2)
  document.documentElement.style.fontSize = size + 'px'
}


// !挂载全局方法
(React as any).request = request; //全局axios 方法
(React as any).pageType = pageType(); //当前页面类型 pc addroid ios
(React as any).reactModal = reactModal; //弹窗
(React as any).reactDrawer = reactDrawer; //抽屉弹窗



function MyApp() {
  const { message } = App.useApp();
  (React as any).message = message; //全局请求地址


  useEffect(() => {
    changeSize()  //页面rem适配
    register();  //swiper 加载的时候需要

    return () => {
      //! 组件卸载时将其删除
      (React as any).request = undefined;
      (React as any).pageType = undefined;
      (React as any).reactModal = undefined;
      (React as any).reactDrawer = undefined;
      (React as any).rotate = undefined;
      (React as any).innerHeight = undefined;
    };

  }, []);


  const [innerHeight, setInnerHeight] = useState(document.documentElement.clientHeight)  //获取高度



  window.addEventListener("resize", function () {
    changeSize()
    setInnerHeight(document.documentElement.clientHeight)   //得到屏幕尺寸 (内部/外部宽度，内部/外部高度)
  }, false);


  (React as any).innerHeight = innerHeight; //拿到高度

  return (
    <>
      <ConfigProvider locale={zhCN}
        theme={{
          token: {
            colorPrimary: "#4eade0",
          },
          components: {
            Select: {
              /* 这里是你的组件 token */
              optionFontSize: 20
            },
          },
        }}
      >
        {/* 兼容低版本浏览器 */}
        <StyleProvider
          hashPriority="high" //要把where删除掉 详细看这个链接 https://ant.design/docs/react/compatible-style-cn
          transformers={[legacyLogicalPropertiesTransformer, px2rem]}
        >
          <BrowserRouter>  {/* 浏览器的路由模式*/}
            <React.Suspense fallback={loading}>   {/* 因为是懒加载，加载过程中的loding */}
              <Routes>
                <Route path="/" element={<RequireAuth><Pay /></RequireAuth>}></Route>
                <Route path="/home" element={<RequireAuth><Home /></RequireAuth>}></Route>
                <Route path="/ml" element={<RequireAuth><Flow /></RequireAuth>}></Route>
                <Route path="/sa" element={<RequireAuth><Flow /></RequireAuth>}></Route>
                <Route path="/flow" element={<RequireAuth><Flow /></RequireAuth>}></Route>
                <Route path="/pay" element={<RequireAuth><Pay /></RequireAuth>}></Route>
                {/* <Route path="/" element={<Main />}></Route> */}
                {/* <Route path="/sa" element={<SaPay />}></Route> */}
                {/* <Route path="/ml" element={<SaPay />}></Route> */}
                <Route path="*" element={<PageNotFound />}></Route> {/* 空路由处理 */}
              </Routes>
            </React.Suspense>
          </BrowserRouter>
        </StyleProvider>
      </ConfigProvider>
    </>

  );
}

export default MyApp;
